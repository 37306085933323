import React, { FC } from "react";
import { ELeaderboardOption } from "../Leaderboard.util";
import { ReactComponent as GlobalIcon } from "../../../assets/images/network.svg";
import { ReactComponent as MarketIcon } from "../../../assets/images/people.svg";
// import { ReactComponent as LocalIcon } from "../../../assets/images/person.svg";

type LeaderboardOptionsProps = {
  selectedOption: string;
  setSelectedOption: (selectedOption: ELeaderboardOption) => void;
};

const LeaderboardOptions: FC<LeaderboardOptionsProps> = ({
  selectedOption,
  setSelectedOption
}: LeaderboardOptionsProps): JSX.Element => {
  const isSelectedStyleApplied = (option: string) =>
    option === selectedOption
      ? "selected-leaderboard-option"
      : "unselected-leaderboard-option";
  const leaderboardOptions = [
    // { title: "Local", icon: <LocalIcon />, type: ELeaderboardOption.Local },
    { title: "Market", icon: <MarketIcon />, type: ELeaderboardOption.Market },
    { title: "Global", icon: <GlobalIcon />, type: ELeaderboardOption.Global }
  ];
  return (
    <div className="leaderboard-options">
      {leaderboardOptions.map(leaderboardOption => {
        const { title, icon, type } = leaderboardOption;
        return (
          <div
            key={title}
            className={`leaderboard-option ${isSelectedStyleApplied(title)}`}
            onClick={() => setSelectedOption(type)}
          >
            <span className="leaderboard-option-icon">{icon}</span>
            {title}
          </div>
        );
      })}
    </div>
  );
};

export default LeaderboardOptions;
