import React from "react";
import "./NavLogo.scss";
import { NavLink } from "react-router-dom";
import { deviceWidthBreakpoints } from "../../utils/useWindowSize";
import { getResponsiveClassName } from "../../utils/responsiveClassHelpers";

type NavLogoProps = {
  width: number;
  wrap?: boolean;
};

const { MOBILE_MAX } = deviceWidthBreakpoints;

const NavLogo: React.FC<NavLogoProps> = ({ width, wrap }: NavLogoProps) => {
  return (
    <NavLink
      to="/"
      className={`nav-logo-${getResponsiveClassName(width)} ${wrap &&
        "top-left"}`}
    >
      <img
        src={
          width <= MOBILE_MAX
            ? require("../../assets/images/prismatica-logo-mobile.svg")
            : require("../../assets/images/prismatica-logo.svg")
        }
        alt="logo"
      />
    </NavLink>
  );
};

export default NavLogo;
