import React, { useEffect } from "react";
import "./ErrorPage.scss";
import { getResponsiveClassName } from "../../utils/responsiveClassHelpers";

import useWindowSize, {
  deviceWidthBreakpoints
} from "../../utils/useWindowSize";
import { Link } from "react-router-dom";
import Button from "../../components/Button/Button";
import LinkButton from "../../components/LinkButton/LinkButton";
import { EButtonStyle } from "../../utils/buttonUtils";

import errorImageDesktop from "../../assets/images/Error-Desktop.svg";

import errorImageTablet from "../../assets/images/Error-Tablet.svg";

import errorImageMobile from "../../assets/images/Error-Mobile.svg";

interface ErrorPageProps {
  setShowNavBars: (setVal: boolean) => void;
}

const { TABLET_MAX, MOBILE_MAX } = deviceWidthBreakpoints;

const renderBackgroundMedia = (width: number) =>
  width > TABLET_MAX
    ? renderBackgroundImage(errorImageDesktop)
    : width > MOBILE_MAX
    ? renderBackgroundImage(errorImageTablet)
    : renderBackgroundImage(errorImageMobile);

const getBtnResponsiveClass = (width: number): string => {
  return `error-btn-container-${getResponsiveClassName(width)}`;
};
const getErrorPageContainerClass = (width: number): string => {
  return `error-page-container-${getResponsiveClassName(width)}`;
};
const mobileOrDesktop = (width: number): string => {
  return `error-page-${getResponsiveClassName(width)}`;
};

const renderBackgroundImage = (image: string): JSX.Element => (
  <div>
    <div>
      <img src={image} alt="" className="errorpage-background-image"></img>
    </div>
  </div>
);

const ErrorPage = ({ setShowNavBars }: ErrorPageProps) => {
  const { width } = useWindowSize();

  useEffect(() => {
    setShowNavBars(false);
  }, [setShowNavBars]);

  return (
    <>
      <div className="errorpage-video-overlay" />

      <div className={`app-page error-page ${mobileOrDesktop(width)}`}>
        {renderBackgroundMedia(width)}
        <div className={getErrorPageContainerClass(width)}>
          <div className={getBtnResponsiveClass(width)}>
            <Link to="/">
              <Button
                type="button"
                onClick={() => setShowNavBars(true)}
                value="Return Home"
              />
            </Link>

            <LinkButton
              text="Contact Prismatica team >"
              style={EButtonStyle.SECONDARY}
              href="mailto: prismatica@slalom.com"
              newTab={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
