import React, { FC } from "react";
import "./PointsCard.scss";

interface PointsCardProps {
  text: string;
}

const ProfileCard: FC<PointsCardProps> = ({
  text
}: PointsCardProps): JSX.Element => {
  return <div className="points-card">{text}</div>;
};

export default ProfileCard;
