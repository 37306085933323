import React from "react";
import SponsorTile from "../SponsorTile/SponsorTile";
import "./AboutPartnersList.scss";
import useWindowSize from "../../utils/useWindowSize";
import { getResponsiveClassName } from "../../utils/responsiveClassHelpers";
import { Event } from "../../utils/consts/event";

const event = Event;

const renderPartnersList = (Partners: any): JSX.Element => {
  return Partners.map(sponsor => (
    <div key={sponsor.name}>
      <SponsorTile sponsor={sponsor} />
    </div>
  ));
};

const aboutPartnersListResponsive = (width: number) => {
  return `about-partners-${getResponsiveClassName(width)}`;
};

const AboutPartnersList = () => {
  const { width } = useWindowSize();
  return (
    <>
      <div className={`${aboutPartnersListResponsive(width)}`}>
        {renderPartnersList(event.aboutPage.aboutSponsors)}
      </div>
    </>
  );
};

export default AboutPartnersList;
