import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import InfoIconLarge from "../../assets/images/InfoIcon-large.svg";
import InfoIconTextLarge from "../../assets/images/InfoIconText-large.svg";
import InfoIconSmall from "../../assets/images/InfoIcon-small.svg";
import InfoIconTextSmall from "../../assets/images/InfoIconText-small.svg";
import CloseIcon from "../../assets/images/tooltip_close.svg";
import "./Tooltip.scss";
import useDetectOutsideClick from "../../hooks/useDetectOutsideClick";
import useWindowSize, {
  deviceWidthBreakpoints
} from "../../utils/useWindowSize";

const { TABLET_MAX } = deviceWidthBreakpoints;
const Tooltip = ({ children }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const { width } = useWindowSize();
  const tooltipContentRef = useRef(null);
  useDetectOutsideClick(tooltipContentRef, () => setTooltipVisible(false));

  return (
    <div className="tooltip-container">
      <div className="tooltip-icon">
        <img
          className="tooltip-icon__text"
          onClick={() => setTooltipVisible(true)}
          src={width <= TABLET_MAX ? InfoIconTextSmall : InfoIconTextLarge}
          alt="tooltip icon text"
        />
        <img
          className="tooltip-icon__circle"
          onClick={() => setTooltipVisible(true)}
          src={width <= TABLET_MAX ? InfoIconSmall : InfoIconLarge}
          alt="tooltip icon"
        />
      </div>
      {tooltipVisible && (
        <div ref={tooltipContentRef} className="tooltip-content">
          <div className="tooltip-close">
            <img
              onClick={() => setTooltipVisible(false)}
              src={CloseIcon}
              alt=""
            />
          </div>
          {children}
        </div>
      )}
    </div>
  );
};

Tooltip.propTypes = {
  children: PropTypes.any
};

export default Tooltip;
