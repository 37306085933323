/* eslint-disable react/no-unescaped-entities */
import React from "react";
import "./OtherWaysToParticipate.scss";
import LinkButton from "../../components/LinkButton/LinkButton";
import Card from "../Card/Card";
import Tooltip from "../Tooltip/Tooltip";
import { EButtonStyle } from "../../utils/buttonUtils";
//import { cleanup } from "@testing-library/react";
import outlinedIcon from "../../assets/images/outlined-icon.png";

const CLAIM_POINTS_PAGE = "/events";
const EXPLORE_PRISM_RESOURCES =
  "https://twodegrees1.sharepoint.com/teams/IandD/SitePages/PRISM.aspx";
const MAIL_TO_SECURITY = "mailto:security@slalom.com";
const SHARE_PICTURES =
  "https://forms.microsoft.com/Pages/ResponsePage.aspx?id=KFGnnESilkWHe_JIKOR24k4dkNXmv-ZAsIJSwZ0u4IZUQ1JJWkFVRkY2M0lURlYxSVg2NzQzM0c1Ty4u";
const GET_CERTIFIED =
  "https://forms.microsoft.com/Pages/ResponsePage.aspx?id=KFGnnESilkWHe_JIKOR24k4dkNXmv-ZAsIJSwZ0u4IZUQ1JJWkFVRkY2M0lURlYxSVg2NzQzM0c1Ty4u";
const PRISM_SPEAKS =
  "https://twodegrees1.sharepoint.com/teams/IandD/SitePages/Prism-Speaks.aspx?xsdata=MDV8MDF8fDgwODJkMGIyNGNmMDRjZjRjYTIzMDhkYjVkNGI1OTg0fDljYTc1MTI4YTI0NDQ1OTY4NzdiZjI0ODI4ZTQ3NmUyfDB8MHw2MzgyMDYzNTA0MDg4NjIyNDN8VW5rbm93bnxWR1ZoYlhOVFpXTjFjbWwwZVZObGNuWnBZMlY4ZXlKV0lqb2lNQzR3TGpBd01EQWlMQ0pRSWpvaVYybHVNeklpTENKQlRpSTZJazkwYUdWeUlpd2lWMVFpT2pFeGZRPT18MXxMMk5vWVhSekx6RTVPbTFsWlhScGJtZGZUVEpHYWs1NlZYZFBSRkYwVFcxV2FFMVRNREJaYlVab1RGUm5NRTFxWTNSTk1rVXhXbGROTTFscVJYZE5WMWt4UUhSb2NtVmhaQzUyTWk5dFpYTnpZV2RsY3k4eE5qZzFNRE00TWpNNU1qRTF8YzQ5MjE5ZjUwODU4NDY5M2NhMjMwOGRiNWQ0YjU5ODR8MWQyNjcxMjEzNjFmNDk5NTg3MTBhZDY2OTg3YzEwZWU%3D&sdata=amVya1BsMzcrNzJ1M3hRdDVqdlR2elI5WHVUMkdUS0FDSlMvK2svdEM1ST0%3D&ovuser=9ca75128-a244-4596-877b-f24828e476e2%2Cavery.green%40slalom.com&OR=Teams-HL&CT=1685113105844&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiIyOC8yMzA0MDIwMjcwNSIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D";

interface CardProps {
  title: string;
  points: number;
  description: React.ReactElement;
  clue: React.ReactElement;
  includeClue: boolean;
  buttonValue: string;
  buttonHref: string;
}

const OtherWaysToParticipateCard: React.FC<CardProps> = ({
  title,
  points,
  description,
  clue,
  includeClue,
  buttonValue,
  buttonHref
}): React.ReactElement => (
  <Card>
    <div>
      <div className="tile-title">
        {title}
        {includeClue && (
          <div className="tile-tooltip">
            <Tooltip>{clue}</Tooltip>
          </div>
        )}
      </div>
      <div className="tile-points">
        <img src={outlinedIcon} alt="points" />
        <div className="points-value">{points} Points</div>
      </div>
      <div className="tile-description">{description}</div>
    </div>
    <div></div>
    <div className="tile-button-container">
      <LinkButton
        text={buttonValue}
        style={EButtonStyle.PRIMARY}
        href={buttonHref}
        newTab={true}
      />
    </div>
  </Card>
);

const signUpForOnePasswordDescription = () => (
  <p className="tile-description">
    Request an invite from{" "}
    <a href={MAIL_TO_SECURITY} target="_blank" rel="noopener noreferrer">
      security@slalom.com
    </a>{" "}
    and mention it is part of Prismatica in order to earn points!
    <br></br>
    <br></br>
    <i>
      If you have already signed up for One Password, your points will be added
      soon.
    </i>
    <br></br>
    <br></br>
    <br></br>
  </p>
);

const HiddenSecuryetiDescription = () => (
  <p className="tile-description">
    Think you've got what it takes to find our hidden Securyeti? Find him and
    follow the instructions you see to get some extra points!
    <br></br>
    <br></br>
    <i>
      <b>Hint: </b>You might want to inspect the page closely to find him, but
      he's not on the About Page!
    </i>
  </p>
);

const ExplorePrismResourcesDescription = () => (
  <p className="tile-descriptions">
    Explore the Prism SharePoint page and hunt down Prismatica Point codes
    hidden throughout the pages.
  </p>
);

const PrideMonthPicsDescription = () => (
  <p className="tile-descriptions">
    Email us your pics from your Pride Month celebrations to earn Prismatica
    Points.
  </p>
);

const GetCertifiedDescription = () => (
  <p className="tile-descriptions">
    If you get certified with any of partners during June, send it in to get
    Prismatica Points.
  </p>
);

const PrismSpeaksDescription = () => (
  <p className="tile-descriptions">
    Check out the Prism Speaks page on June 29 to hear 7 Slalom LGBTQ+ stories.
  </p>
);

const HiddenSecuryetiClue = () => (
  <p className="tile-description">
    You might want to inspect the page closely to find him.
  </p>
);

interface OtherwaysToParticipateCardContent {
  title: string;
  points: number;
  descriptionComponent: () => React.ReactElement;
  clue: () => React.ReactElement;
  includeClue: boolean;
  button: { value: string; href: string };
}
const cardContent: OtherwaysToParticipateCardContent[] = [
  {
    title: "Join Slalom on One Password",
    points: 300,
    descriptionComponent: signUpForOnePasswordDescription,
    clue: HiddenSecuryetiDescription,
    includeClue: false,
    button: { value: "Join 1Password", href: MAIL_TO_SECURITY }
  },
  {
    title: "Find the Hidden Yeti!",
    points: 200,
    descriptionComponent: HiddenSecuryetiDescription,
    clue: HiddenSecuryetiClue,
    includeClue: false,
    button: { value: "Claim Points", href: CLAIM_POINTS_PAGE }
  },
  {
    title: "Explore Prism Resources",
    points: 300,
    descriptionComponent: ExplorePrismResourcesDescription,
    clue: HiddenSecuryetiClue,
    includeClue: false,
    button: { value: "Explore", href: EXPLORE_PRISM_RESOURCES }
  },
  {
    title: "Share Your Pride Month Pics",
    points: 300,
    descriptionComponent: PrideMonthPicsDescription,
    clue: HiddenSecuryetiClue,
    includeClue: false,
    button: { value: "Share", href: SHARE_PICTURES }
  },
  {
    title: "Get Certified",
    points: 300,
    descriptionComponent: GetCertifiedDescription,
    clue: HiddenSecuryetiClue,
    includeClue: false,
    button: { value: "Submit", href: GET_CERTIFIED }
  },
  {
    title: "Prism Speaks",
    points: 500,
    descriptionComponent: PrismSpeaksDescription,
    clue: HiddenSecuryetiClue,
    includeClue: false,
    button: { value: "Check it out!", href: PRISM_SPEAKS }
  }
];

const OtherWaysToParticipate = () => {
  return (
    <div className="other-ways-to-earn-page section-container">
      <div className="section-title small">Other ways to earn points</div>
      <div className="other-ways-to-earn-container">
        {cardContent.map(
          (
            { title, points, descriptionComponent, clue, includeClue, button },
            idx
          ) => (
            <OtherWaysToParticipateCard
              key={`other-ways-to-earn-card-${idx}`}
              title={title}
              points={points}
              description={descriptionComponent()}
              clue={clue()}
              includeClue={includeClue}
              buttonValue={button.value}
              buttonHref={button.href}
            />
          )
        )}
      </div>
    </div>
  );
};

export default OtherWaysToParticipate;
