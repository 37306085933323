import React from "react";
import "./SplashPage.scss";
import useWindowSize, {
  deviceWidthBreakpoints
} from "../../utils/useWindowSize";
import splashPageUltrawide from "../../assets/images/splash-desktop.svg";
import splashPageDesktop from "../../assets/images/splash-desktop.svg";
import splashPageTablet from "../../assets/images/splash-tablet.svg";
import splashPageMobile from "../../assets/images/splash-mobile.svg";
import { getResponsiveClassName } from "../../utils/responsiveClassHelpers";

const { LAPTOP_MAX, TABLET_MAX, MOBILE_MAX } = deviceWidthBreakpoints;

const renderResponsiveBackgroundMedia = (width: number) =>
  width > LAPTOP_MAX
    ? renderUltrawideBackgroundImg()
    : width > TABLET_MAX
    ? renderDesktopBackgroundImg()
    : width > MOBILE_MAX
    ? renderTabletBackgroundImg()
    : renderMobileBackgroundImg();

const renderUltrawideBackgroundImg = (): JSX.Element => (
  <div>
    <img
      src={splashPageUltrawide}
      alt=""
      className="splashpage-background-image"
    ></img>
  </div>
);
const renderDesktopBackgroundImg = (): JSX.Element => (
  <div>
    <img
      src={splashPageDesktop}
      alt=""
      className="splashpage-background-image"
    ></img>
  </div>
);
const renderTabletBackgroundImg = (): JSX.Element => (
  <div>
    <img
      src={splashPageTablet}
      alt=""
      className="splashpage-background-image"
    ></img>
  </div>
);
const renderMobileBackgroundImg = (): JSX.Element => (
  <div>
    <img
      src={splashPageMobile}
      alt=""
      className="splashpage-background-image"
    ></img>
  </div>
);

const SplashPage = () => {
  const { width } = useWindowSize();

  return (
    <main className={`splash-container-${getResponsiveClassName(width)}`}>
      {renderResponsiveBackgroundMedia(width)}
    </main>
  );
};

export default SplashPage;
