import React, { FC } from "react";
import "./ProfileCardList.scss";
import ProfileCard from "../ProfileCard/ProfileCard";

type Participants = {
  image: any;
  firstName: string;
  lastName: string;
  market: string;
  user: string;
};

interface ProfileCardListProps {
  participants: Array<Participants>;
}

const renderProfileContactList = (participants: Array<Participants>) =>
  participants.map(participant => (
    <ProfileCard key={participant.user} participant={participant} />
  ));

const ProfileCardList: FC<ProfileCardListProps> = ({
  participants
}: ProfileCardListProps): JSX.Element => (
  <div className="profile-card-list-container" data-testid="profile-list">
    {renderProfileContactList(participants)}
  </div>
);

export default ProfileCardList;
