import React, { FC } from "react";
import useWindowSize, {
  deviceWidthBreakpoints
} from "../../../utils/useWindowSize";
import TopLeaders from "../../TopLeaders/TopLeaders";
import LoadLeaderListing from "../LoadLeaderListing/LoadLeaderListing";

const { MOBILE_MAX } = deviceWidthBreakpoints;

type LeaderboardOrFallbackProps = {
  selectedOption: "Local" | "Global" | "Market" | string;
  isLocalLeaderBoard: Boolean;
  sortedParticipants: Array<any>;
  error: any;
  isLoading: boolean;
};

const renderLeaderBoard = (
  isLocalLeaderBoard,
  width,
  sortedParticipants,
  selectedOption,
  error,
  isLoading
) => (
  <>
    <TopLeaders
      width={width}
      sortedParticipants={sortedParticipants}
      selectedOption={selectedOption}
      error={error}
      isLoading={isLoading}
    />
    {width > MOBILE_MAX && !isLocalLeaderBoard && (
      <LoadLeaderListing
        width={width}
        sortedParticipants={sortedParticipants}
        selectedOption={selectedOption}
        error={error}
        isLoading={isLoading}
      />
    )}
  </>
);

const renderFallbackMessage = () => (
  <div className="leaderboard-default-msg">
    Want to see your name on the leaderboard? Check out our other ways to earn
    points to get started!
  </div>
);

const LeaderboardOrFallback: FC<LeaderboardOrFallbackProps> = ({
  isLocalLeaderBoard,
  sortedParticipants,
  selectedOption,
  error,
  isLoading
}: LeaderboardOrFallbackProps) => {
  const { width } = useWindowSize();
  return sortedParticipants && sortedParticipants?.length > 0
    ? renderLeaderBoard(
        isLocalLeaderBoard,
        width,
        sortedParticipants,
        selectedOption,
        error,
        isLoading
      )
    : renderFallbackMessage();
};

export default LeaderboardOrFallback;
