import React from "react";
import "./AboutPage.scss";
import AboutInfoList from "../../components/AboutInfoList/AboutInfoList";
import useWindowSize, {
  deviceWidthBreakpoints
} from "../../utils/useWindowSize";
import { getResponsiveClassName } from "../../utils/responsiveClassHelpers";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import ScrollToTop from "../../components/ScrollToTop";
import Card from "../../components/Card/Card";
import { Event } from "../../utils/consts/event";
import AboutPartnersList from "../../components/AboutPartnersList/AboutPartnersList";
import aboutBannerDesktop from "../../assets/images/headers/about/about-hero-image.png";
import aboutBannerTablet from "../../assets/images/headers/about/about-hero-image-tablet.png";
import aboutBannerMobile from "../../assets/images/headers/about/about-hero-image-mobile.png";
import LinkButton from "../../components/LinkButton/LinkButton";

const { MOBILE_MAX, TABLET_MAX } = deviceWidthBreakpoints;

const getAboutHeaderResponsiveClass = width =>
  `about-header-${getResponsiveClassName(width)}`;

const getAboutWelcomeSectionResponsiveClass = width =>
  `welcome-section-${getResponsiveClassName(width)}`;

const getAboutContactSectionResponsiveClass = width =>
  `contact-section-container-${getResponsiveClassName(width)}`;

const event = Event;

const AboutPage = () => {
  const { width } = useWindowSize();
  const aboutBanner =
    width > TABLET_MAX
      ? aboutBannerDesktop
      : width > MOBILE_MAX
      ? aboutBannerTablet
      : aboutBannerMobile;
  const url =
    "https://sparkthink.slalom.com/survey/s/9d27e851-17c0-4b2f-be28-513b3cea340b";
  return (
    <div>
      <ScrollToTop>
        <PageWrapper bannerImage={aboutBanner}>
          <div className="about-container">
            <section
              className={`welcome-section ${getAboutContactSectionResponsiveClass(
                width
              )} ${getAboutWelcomeSectionResponsiveClass(width)}`}
            >
              <div>
                <div className="section-title small">
                  {event.aboutPage.noteFromPrismaticaTeamHeader}
                </div>
                <Card
                  containerClass={`contact-card contact-card-wide-${getResponsiveClassName(
                    width
                  )}`}
                >
                  <div className="contact-section-title">Got feedback?</div>
                  <div className="about-page-text-body-container">
                    {event.aboutPage.noteFromPrismaticaTeam}
                  </div>
                </Card>
              </div>
              <div>
                <div className="section-title small section-title-last">
                  {event.aboutPage.noteFromInfoSecTeamHeader}
                </div>
                <Card
                  containerClass={`contact-card contact-card-wide-${getResponsiveClassName(
                    width
                  )}`}
                >
                  <div className="contact-section-title">
                    Join the Events Platform Build Team!
                  </div>
                  <div className="about-page-text-body-container">
                    {event.aboutPage.noteFromInfoSecTeam}
                  </div>
                </Card>
              </div>
            </section>
            <section
              className={`welcome-section ${getAboutContactSectionResponsiveClass(
                width
              )} ${getAboutWelcomeSectionResponsiveClass(width)}`}
            >
              <Card
                containerClass={`contact-card contact-card-${getResponsiveClassName(
                  width
                )}`}
              >
                <div className="contact-section-title">Got feedback?</div>
                <div className="about-page-text-body-container">
                  {event.aboutPage.aboutEventsFeedbackText}
                </div>
                <LinkButton
                  href="mailto: prismatica@slalom.com"
                  newTab={true}
                  text="Contact Us"
                />
              </Card>
              <Card
                containerClass={`contact-card contact-card-${getResponsiveClassName(
                  width
                )}`}
              >
                <div className="contact-section-title">
                  Join the Events Platform Build Team!
                </div>
                <div className="about-page-text-body-container">
                  {event.aboutPage.aboutEventsJoinText}
                </div>
                <LinkButton href={url} newTab={true} text="Join Us" />
              </Card>
              <Card
                containerClass={`contact-card contact-card-${getResponsiveClassName(
                  width
                )}`}
              >
                <div className="contact-section-title">Caught a bug?</div>
                <div className="about-page-text-body-container">
                  {event.aboutPage.aboutEventsBugText}
                </div>
                <LinkButton
                  href="mailto: zenith@slalom.com"
                  newTab={true}
                  text="Contact Us"
                />
              </Card>
            </section>

            <AboutPartnersList />
            {Object.keys(event.aboutPage.aboutTeams).map((team, idx) => {
              return (
                <div key={idx}>
                  {team === "Ownership" ? (
                    <div>
                      <div
                        className={`about-header ${getAboutHeaderResponsiveClass(
                          width
                        )}`}
                      >
                        Solution Ownership
                      </div>
                      <AboutInfoList
                        participants={event.aboutPage.aboutTeams[team]}
                      />
                    </div>
                  ) : team === "Quality" ? (
                    <div>
                      <div
                        className={`about-header ${getAboutHeaderResponsiveClass(
                          width
                        )}`}
                      >
                        Quality Engineering
                      </div>
                      <AboutInfoList
                        participants={event.aboutPage.aboutTeams[team]}
                      />
                    </div>
                  ) : (
                    <div>
                      <div
                        className={`about-header ${getAboutHeaderResponsiveClass(
                          width
                        )}`}
                      >
                        {team}
                      </div>
                      <AboutInfoList
                        participants={event.aboutPage.aboutTeams[team]}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </PageWrapper>
      </ScrollToTop>
    </div>
  );
};

export default AboutPage;
