const currDateTime = new Date().getTime();
const startDateTime = new Date(2023, 5, 1, 0).getTime();
const endDateTime = new Date(2023, 6, 1, 0).getTime();
const winnersAnnouncedDateTime = new Date(2023, 6, 6, 0).getTime();

export const isBeforeEventStart = currDateTime < startDateTime;

export const eventHasEnded = currDateTime >= endDateTime;
export const showWinners = currDateTime >= winnersAnnouncedDateTime;
export const showEndAnnouncements = eventHasEnded && !showWinners;

export const showFullSite =
  currDateTime >= startDateTime && currDateTime < endDateTime;
