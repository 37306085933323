import React, { FC, useState } from "react";
import { useMutation, queryCache } from "react-query";
import { postEventClaim } from "../../ApiHelper";
import "./ClaimPoints.scss";
import useWindowSize from "../../utils/useWindowSize";
import { getResponsiveClassName } from "../../utils/responsiveClassHelpers";
import { EButtonStyle } from "../../utils/buttonUtils";
import Button from "../Button/Button";

const ClaimPoints: FC = () => {
  const [claimState, setClaimState] = useState("home");
  const [invalid, setIsInvalid] = useState(false);
  const [claimsCode, setClaimsCode] = useState("");

  const { width } = useWindowSize();

  const getInnerContainerClass = width =>
    `claim-points-bar-${getResponsiveClassName(width)}`;
  const getFormClass = width =>
    `claim-points-form-inline-${getResponsiveClassName(width)}`;
  const getDescriptionContainerClass = width =>
    `description-container-${getResponsiveClassName(width)}`;
  const getInputClass = () =>
    invalid
      ? "claim-points-input-error"
      : claimState === "success"
      ? "claim-points-input-success"
      : "";

  const [mutate] = useMutation(postEventClaim, {
    onSuccess: data => {
      if (data === null) {
        setIsInvalid(true);
      } else {
        setClaimState("success");
      }
    },
    onSettled: () => {
      queryCache.invalidateQueries("participant");
      queryCache.invalidateQueries("participants");
    }
  });

  const handleClick = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (claimsCode.length >= 4 && claimsCode.length <= 6) {
      mutate(claimsCode);
    }
  };

  const clearSubmissionState = () => {
    setClaimState("home");
    setIsInvalid(false);
    setClaimsCode("");
  };

  const handleClaimsCodeOnChange = (value: string) => {
    const code = value?.toUpperCase() ?? "";

    setClaimsCode(code);
  };

  return (
    <section className="section-container">
      <div className="section-title claim-points-label">Claim points</div>
      <div className={`claim-points-bar ${getInnerContainerClass(width)}`}>
        <div className={`claim-points-row-${getResponsiveClassName(width)}`}>
          <div
            className={`claim-points-description-container ${getDescriptionContainerClass(
              width
            )}`}
          >
            <div className="claim-points-header">
              Attended an event or completed an activity? Claim your points now!
            </div>
            <div
              className={`claim-points-body-${getResponsiveClassName(width)}`}
            >
              Enter your unique code here to earn points! If you&apos;ve lost
              your event code, ping your event lead so you don&apos;t miss out
              on points!
            </div>
          </div>
          <form className={`claim-points-form-inline ${getFormClass(width)}`}>
            <div
              className={`claim-points-enter-code-container-${getResponsiveClassName(
                width
              )}`}
            >
              <div className="claim-points-header-secondary">Unique code</div>
              <input
                className={`claim-points-input ${getInputClass()}`}
                type="text"
                name="code"
                maxLength={6}
                onChange={event => handleClaimsCodeOnChange(event.target.value)}
                onFocus={() => clearSubmissionState()}
                placeholder="Enter Code"
                value={claimsCode}
              />
              <div id="claim-points-input-message">
                {invalid
                  ? "Uh oh! Please try again."
                  : claimState && claimState === "success"
                  ? "Success!"
                  : ""}
              </div>
            </div>
            <div
              className={`claim-points-submit-container-${getResponsiveClassName(
                width
              )}`}
            >
              <div
                className={`claim-points-button-container-${getResponsiveClassName(
                  width
                )}`}
              >
                <Button
                  style={`${EButtonStyle.PRIMARY} test ${
                    claimsCode.length < 4 ||
                    invalid === true ||
                    claimState === "success"
                      ? "disabled"
                      : ""
                  }`}
                  type="button"
                  onClick={handleClick}
                  value="Submit"
                  disabled={
                    claimsCode.length < 4 ||
                    invalid === true ||
                    claimState === "success"
                  }
                >
                  Submit
                </Button>
              </div>
              <div
                className={`claim-points-button-message-${getResponsiveClassName(
                  width
                )}`}
              >
                {(claimsCode.length < 4 ||
                  invalid === true ||
                  claimState === "success") && (
                  <>Complete section to continue</>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ClaimPoints;
