import { Markets } from "./markets";
import { Roles } from "./roles";
// import { ProfileImageShapes } from "./profileImageShapes";
//import { Titles } from "./titles";
const DEFAULT_COVER = require("../../assets/images/about-profile-default.png");

export const Event = {
  eventName: "[EventName]",
  eventDescription: "[Some Event Details Here .........]",
  additionalDetails: "[Add Event Additional Details Here ........]",
  startDate: "Start Date",
  endDate: "End Date",
  nextYear: 2023,
  announcements: [],
  timeLineAndPrizes: [
    {
      title: "timeline-title-1",
      description: "timeline and prizes description - 1"
    },
    {
      title: "timeline-title-2",
      description: "timeline and prizes description - 2"
    },
    {
      title: "timeline-title-3",
      description: "timeline and prizes description - 3"
    },
    {
      title: "timeline-title-4",
      description: "timeline and prizes description - 4"
    }
  ],
  faqSections: [
    {
      name: "About",
      sectionData: [
        {
          title: "Why is the site called Prismatica?",
          body: [
            {
              itemText:
                "The name Prismatica was inspired by the many colorful reflections that make up the LGBTQ+ community. We also LOVE Lady Gaga!"
            }
          ]
        },
        {
          title: "What is this platform?",
          body: [
            {
              itemText:
                "This platform may look familiar to you if you participated in events utilizing the Events Platform last year, or Hacktober. Our events platform blends cybersecurity with Slalom events to bring a fun, unique way to interact with our ERG and ID&E groups!"
            }
          ]
        },
        {
          title:
            "I need accommodation due to neurodiversity, visual & audio impairment, etc. What should I do?",
          body: [
            {
              itemText:
                "Please email zenith@slalom.com and include the accommodations you need to enable your participation."
            }
          ]
        },
        {
          title: "When is Pride?",
          body: [
            {
              itemText:
                "At Slalom, Pride runs during the International Month of Pride in June. Pride is celebrated at different times of year around the world. Prismatica will run from June 1 - June 30."
            }
          ]
        }
      ]
    },
    {
      name: "Activities",
      sectionData: [
        {
          title: "What is a Question of the Day?",
          body: [
            {
              itemText:
                "The Question of the Day is a daily quiz during Pride Month that, if answered correctly, will earn you points. The questions refresh every 24 hours, which is unique to this event, so be sure to check back everyday for a chance to earn points!"
            }
          ]
        },
        {
          title: "Are all the events global and open to everyone?",
          body: [
            {
              itemText:
                "Yes -- all events are virtual and the time for each event is reflected in your time zone!"
            }
          ]
        },
        {
          title: "How do I sign up for an event?",
          body: [
            {
              itemText:
                "Review the events calendar on the Events page. Please use the “Learn More” button to send an email to the event organizer to get added to the invite. If you are having trouble, please email prismatica@slalom.com  and the team can forward you the invite directly."
            }
          ]
        },
        {
          title: "Can I join all of the events on the Prismatica site?",
          body: [
            {
              itemText:
                "The events that are open to everyone will be noted in the event description."
            }
          ]
        },
        {
          title: "Why should I support local events?",
          body: [
            {
              itemText:
                "You should support your local Prism events to show your allyship to your local LGBTQ+ community and Slalomers."
            }
          ]
        },
        {
          title: "Why should I attend global events?",
          body: [
            {
              itemText:
                "You should attend global Prism events to connect with other Prism chapters at Slalom."
            }
          ]
        }
      ]
    },
    {
      name: "Site",
      sectionData: [
        {
          title:
            "Can I invite clients or people external to Slalom to this event?",
          body: [
            {
              itemText:
                "No, please do not share any meetings or events externally! The Events Platform is exclusively built for Slalom employees."
            }
          ]
        },
        {
          title:
            "If I watch an event recording, can I still use the code to earn points?",
          body: [
            {
              itemText:
                "Yes -- event codes will only work until the end of Pride Month, however, so please be sure to input your code by then."
            }
          ]
        }
      ]
    },
    {
      name: "Points & Prizes",
      sectionData: [
        {
          title: "How do you earn points for Prismatica?",
          body: [
            {
              itemText:
                "There are many ways to earn points. You can earn points by answering the Question of the Day, attending events, and completing activities listed under Other Ways to Earn Points."
            }
          ]
        },
        {
          title:
            "Who do I contact if I think my point total is tallied incorrectly?",
          body: [
            {
              itemText:
                'If you feel that you have an incorrect point total, please reach out to zenith@slalom.com and our development team will investigate! Please note that the "other ways to earn points" section has points added manually, so we appreciate your patience as those get added.'
            }
          ]
        },
        {
          title: "What prizes are available?",
          body: [
            {
              itemText: `At the end of the month, the Securyeti will tally all the points acquired and award prizes for the following categories:\
                
              
Top Three Markets: The top 3 markets will receive a special Prismatica trophy, with the #1 market receiving the coveted Prismatica Traveling Trophy!\


Top Three Individuals: The top 3 individuals will receive a special one-of-a-kind Slalom Prism Merch!`
            }
          ]
        },
        {
          title: "When will winners be announced?",
          body: [
            {
              itemText:
                "Winners will be announced by July 14, and prizes will be distributed by August 31 due to shipping delays. Please look out for an email if you've won!"
            }
          ]
        }
      ]
    },
    {
      name: "Contact",
      sectionData: [
        {
          title: "Who can I contact if I am having issues with the site?",
          body: [
            {
              itemText:
                "Pease email zenith@slalom.com  with screenshots and a thorough description of the issue, and someone from our team will work to troubleshoot."
            }
          ]
        },
        {
          title: "I have a question about an event. Who can I contact?",
          body: [
            {
              itemText: "Please reach out to prismatica@slalom.com"
            }
          ]
        },
        {
          title: "Who can I contact regarding Prismatica questions?",
          body: [
            {
              itemText: "Please reach out to prismatica@slalom.com"
            }
          ]
        },
        {
          title:
            "Who developed this events platform, and how can I get involved?",
          body: [
            {
              itemText:
                "A wonderful team of volunteers brought this platform to life. You can check them out by visiting the About page. Interested in participating in the next events platform build? Sign up via the About page!"
            }
          ]
        },
        {
          title:
            "I’d love to use the Event Platform for the work I’m doing. Who can I reach out to?",
          body: [
            {
              itemText: "Please reach out to zenith@slalom.com"
            }
          ]
        }
      ]
    }
  ],
  partnersList: [
    {
      name: "Microsoft",
      link:
        "https://twodegrees1.sharepoint.com/teams/GlobalAlliances/SitePages/Microsoft.aspx",
      image: null
    }
  ],
  aboutPage: {
    title: "Welcome to Slalom Women's History Month",
    eventIntroText:
      "Slalom City Limits first took the stage in 2016 and brought employees together to teach, learn and have fun. Seven years later, Slalom City Limits has grown into an international festival inspiring Slalomers across the globe as we continue to drive an inclusive learning culture. Every year, our goal is to live the Slalom core values of Stay Humble and Curious and Celebrate Authenticity. Everyone plays a part in creating an amazing show so thank you to the artists, producers, project team, and fans!",
    noteFromPrismaticaTeamHeader: `A Note From The Prismatica Team`,
    noteFromPrismaticaTeam: `This year, Prism’s Pride Month is themed as Prismatica, celebrating the many colorful reflections that make up the LGBTQ+ community. Together, we continue to create a culture of education to increase awareness and understanding while removing workplace barriers for all sexual orientations, romantic orientations, gender identities, and allies. Together, we are raising our voices, amplifying support for our community, and coming together to create a workplace and society equal for all.`,
    noteFromInfoSecTeamHeader: `A Note From The InfoSec Team`,
    noteFromInfoSecTeam: `Information Security and Governance is proud to unveil the first ever Slalom Events Platform, dedicated to bringing Slalom together in fun, secure, and meaningful ways! The Events Platform first began as Hacktober in 2019. Created by Reedhima Mandlik, Hacktober is a way to make cybersecurity engaging and fun. Hacktober has since grown to be a Slalom Women-led initiative in partnership with the Information Security and Governance team, and evolved into the Events Platform to help connect cybersecurity and Slalom Events at a global scale. Our mission is to weave cybersecurity into the fabric of Slalom´s culture and help cultivate a security-first mindset. Together, we are all a vital part of the InfoSec team!`,
    aboutEventsFeedbackText: `We always welcome feedback on our platform or the events we are hosting! Feel free to contact us.`,
    aboutEventsJoinText: `Like what you see? Want experience building a custom platform in React? We welcome team members from every capability. Sign up via our link below!`,
    aboutEventsBugText: `Please report all bugs through our link below!`,
    aboutSponsors: [
      {
        name: "Trevor Project",
        link: "https://www.thetrevorproject.org/",
        image:
          "https://logowik.com/content/uploads/images/the-trevor-project-old4263.logowik.com.webp",
        blurb: ""
      },
      {
        name: "Rainbow Railroad",
        link: "https://www.rainbowrailroad.org/",
        image:
          "https://media.licdn.com/dms/image/C560BAQH3aIQ7DPCYKA/company-logo_200_200/0/1571756271051?e=2147483647&v=beta&t=5pTxn3gSxQqW6_xszG60ggto9OwyrEXsnPRErfzBeOk",
        blurb: ""
      },
      {
        name: "Mermaids",
        link: "https://mermaidsuk.org.uk/",
        image:
          "https://mermaidsuk.org.uk/wp-content/themes/mermaids_theme/media/MermaidsLogo.svg",
        blurb: ""
      },
      {
        name: "Lisbon-und Schwulenverband",
        link: "https://www.lsvd.de/de/",
        image: "https://www.lsvd.de/img/LSVD.png",
        blurb: ""
      },
      {
        name: "Nijiro Diversity",
        link: "https://nijiirodiversity.jp/",
        image:
          "https://nijiirodiversity.jp/wp/wp-content/themes/nijiiro-diversity-theme/images/logo.svg",
        blurb: ""
      },
      {
        name: "InsideOUT",
        link: "https://insideout.org.nz/",
        image:
          "https://insideout.org.nz/wp-content/uploads/2021/02/Insideout-logo-transparent-550.png",
        blurb: ""
      },
      {
        name: "The Rainbow Project",
        link: "https://www.rainbow-project.org/",
        image:
          "https://www.rainbow-project.org/wp-content/uploads/2022/04/SQUARE-LOGO.png",
        blurb: ""
      }
    ],
    aboutTeams: {
      Leadership: [
        {
          fullName: "Avery Green",
          role: Roles.leadership.global,
          market: Markets.detroit,
          professionalPhoto: require("../../assets/images/team/avery-green.jpg"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him"
        },
        {
          fullName: "Reedhima Mandlik",
          role: `${Roles.leadership.founder}, ${Roles.leadership.programDirector}`,
          market: Markets.chicago,
          professionalPhoto: require("../../assets/images/team/Reedhima-Mandlik.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "She/Her"
        }
      ],
      Ownership: [
        {
          fullName: "Isabella Antonini",
          role: Roles.srSolutionOwner,
          market: Markets.chicago,
          professionalPhoto: require("../../assets/images/team/isabella-antonini.jpg"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "She/Her"
        },
        {
          fullName: "Yentl Williams",
          role: Roles.solutionOwner,
          market: Markets.chicago,
          professionalPhoto: require("../../assets/images/team/Yentl-Love-Williams.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "She/Her"
        }
      ],
      Design: [
        {
          fullName: "Laura Manzanarez",
          role: Roles.ux,
          market: Markets.houston,
          professionalPhoto: require("../../assets/images/team/Laura-Manzanarez.jpg"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "She/Her"
        },
        {
          fullName: "Timur Dzhambinov",
          role: Roles.lvd,
          market: Markets.atlanta,
          professionalPhoto: require("../../assets/images/team/timur-dzhambinov.jpg"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him"
        },
        {
          fullName: "Rachel Vorm",
          role: Roles.ux,
          market: Markets.chicago,
          professionalPhoto: require("../../assets/images/team/rachel-vorm.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "She/Her/They/Them"
        }
      ],
      Database: [
        {
          fullName: "Erasmo Velez Baerga",
          role: Roles.smeDatabase,
          market: Markets.chicago,
          professionalPhoto: require("../../assets/images/team/erasmo-baerga.jpg"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him"
        }
      ],
      Development: [
        {
          fullName: "Azfar Ahmed",
          role: Roles.qualityEngineer,
          market: Markets.chicago,
          professionalPhoto: require("../../assets/images/team/Azfar-Ahmed.jpg"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him"
        },
        {
          fullName: "Connie Reynolds",
          role: Roles.softwareEngineer,
          market: Markets.chicago,
          professionalPhoto: require("../../assets/images/about-profile-placeholder.jpg"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "She/Her"
        },
        {
          fullName: "Denise Maldia",
          role: Roles.softwareEngineer,
          market: Markets.chicago,
          professionalPhoto: require("../../assets/images/team/denise-maldia.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "She/Her"
        },
        {
          fullName: "Erika Barrero",
          role: Roles.softwareEngineer,
          market: Markets.houston,
          professionalPhoto: require("../../assets/images/team/erika-barrero.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "She/Her"
        },
        {
          fullName: "Jonathan Olmon",
          role: Roles.softwareEngineer,
          market: Markets.cloudResidency,
          professionalPhoto: require("../../assets/images/team/jonathan-olmon.jpg"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him"
        },
        {
          fullName: "Kevin Orlando",
          role: Roles.smeArchitect,
          market: Markets.charlotte,
          professionalPhoto: require("../../assets/images/team/kevin-orlando.jpg"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him"
        },
        {
          fullName: "Luis Cristo",
          role: Roles.softwareEngineer,
          market: Markets.houston,
          professionalPhoto: require("../../assets/images/team/luis-cristo.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him"
        },
        {
          fullName: "Rashmi Ragupal",
          role: Roles.softwareEngineer,
          market: Markets.seattle,
          professionalPhoto: require("../../assets/images/team/Rashmi-Ragupal.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "She/Her"
        },
        {
          fullName: "Sarah Moreland",
          role: Roles.softwareEngineer,
          market: Markets.boston,
          professionalPhoto: require("../../assets/images/team/sarah-moreland.png"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "She/Her"
        }
      ]
    }
  },
  winnersPage: {
    title: "Welcome to data privacy week",
    section1Title:
      "Thank you for participating in Slalom's first Data Privacy Week!",
    section1Description:
      "The Privacy team is proud to present Slalom's first-ever annual Data Privacy Week celebration, a global initiative dedicated to building data privacy awareness while having fun! Data Privacy Day is an annual international celebration on 28 January. Our mission is to weave privacy into the fabric of Slalom's culture and help cultivate a privacy-first mindset.",
    feedbackTitle: "Got Feedback?",
    feedbackText: `We always welcome feedback on our platform or the events we are hosting! Feel free to contact us.`,
    joinTitle: "Join the Events Platform Build Team!",
    joinText: `Like what you see? Want experience building a custom platform in React? We welcome team members from every capability. Sign up via our link below!`,
    bugTitle: "Caught a bug?",
    bugText: `Please report all bugs through our link below!`,
    noteFromPrismaticaTeamHeader: `A Note From The Prismatica Team`,
    noteFromPrismaticaTeam:
      "Thank you for attending the dozens of Prismatica events this year, discovering our hidden SecurYeti, and participating in events in your community! Your participation this month has brought Slalom closer together and made us a more secure fun place to work.",
    noteFromInfoSecTeamHeader: `A Note From The InfoSec Team`,
    noteFromInfoSecTeam:
      "Curious which event will be hosted on the Events Platform next? Look out for the SecurYeti this October as Hacktober begins! Interested in being part of the next Platform build? Sign up below! If you have feedback on our platform, we would love to hear from you below as well. Thanks, and happy PRIDE!"
  },
  wrapPage: {
    title: "Thank you from the PRISM and InfoSec Team!",
    message: `Thank you for attending the dozens of Prismatica events this year, discovering our hidden SecurYeti, and participating in events in your community! Your participation this month has brought Slalom closer together and made us a more secure fun place to work. Winners are being tallied, and will be released next week!
    
    Curious which event will be hosted on the Events Platform next? Look out for the SecurYeti this October as they discover a spooky portal in their forest... and Hacktober begins! Interested in being part of the next Platform build? Sign up below! If you have feedback on our platform, we would love to hear from you below as well. Thanks, and happy PRIDE!`,
    viewRecordingLink:
      "https://www.myworkday.com/slalom/learning/program/46311abc51f51001becba8a29a400000?type=2d29754fdb8e100008b50ff6bc94003b",
    joinEventsPlatformTeamLink:
      "https://sparkthink.slalom.com/survey/s/f1ba0e98-34e7-4c32-a97e-34f0f7db2661",
    feedbackEmailAddress: "mailto:prismatica@slalom.com",
    sharePointSite:
      "https://twodegrees1.sharepoint.com/teams/IandD/SitePages/PRISM.aspx"
  },
  preLaunchPage: {
    message: `Event specific pre-launch message here: Lorem ipsum dolor sit amet,
    consectetur adipiscing elit. Adipiscing auctor rhoncus, massa
    condimentum nibh amet, pulvinar.`,
    spotifyPlayList: "",
    videoLink: "",
    lineup: {
      1: {
        date: "DAY 1 - MAY 8TH",
        speakers: [
          "Kyle Battle",
          "Ryan McCreedy",
          "Summer Zhang",
          "CJ Gonzalez",
          "Jodi Hill",
          "Spenser Davison",
          "Josh Dix",
          "Alley Lyles-Jenkins",
          "Steve Hering",
          "Sabrina Voeller",
          "Lauren Scott",
          "Jen Macias",
          "Danielle Sheffler",
          "Ayman Samamry"
        ]
      },
      2: {
        date: "DAY 2 - MAY 9TH",
        speakers: [
          "Bermon Painter",
          "Nicole Frederick",
          "William Benn",
          "Sophie Kim",
          "Betsy Cohen",
          "Dora Garnett",
          "Kiran Maanickam",
          "Teresa Davies",
          "Emily Salamander",
          "Meagan Breidert",
          "Stevie Borne",
          "Adam Thies",
          "Arif Kabir",
          "Dan Mackett",
          "Mike Kennard",
          "Becky Jo Tuell-Simpson",
          "Marte Leisy",
          "Shweta Shidhore",
          "Tassia Huq"
        ]
      },
      3: {
        date: "DAY 3 - MAY 10TH",
        speakers: [
          "Martin Gardner",
          "Matt Collinge",
          "Nicole Owuor",
          "Saurabh Bhargava",
          "Alice Leong",
          "Janeen Osselborn",
          "Rene Putz",
          "Sam McClanahan",
          "Vinayak Nagarajan",
          "Kristi Brown",
          "Sireesha Gyara",
          "Dij Thulasi",
          "Ken Rosso"
        ]
      },
      4: {
        date: "DAY 4 - MAY 11TH",
        speakers: [
          "Julius Popoola",
          "Sören Karnstädt",
          "Ramona Proksch",
          "Brian McDaniel",
          "Ronin Sharma",
          "Tim Letscher",
          "Alana Kunkel",
          "Avery Green",
          "Karl Jackson",
          "Danielle Funston",
          "Erica Banks",
          "Ashley Pace",
          "Johanna DeYoung",
          "Richard Winston",
          "Anthony Marquardt",
          "Lisa Pappas",
          "Ankeeta Lal",
          "Ray Wang"
        ]
      }
    }
  }
};
