import Leaderboard from "./Leaderboard";

const marketList = marketsData =>
  marketsData?.map(market => market["name"]).sort();

export const marketFilterOptions = marketsData =>
  marketList(marketsData).map(market => ({
    key: market,
    text: market,
    value: market
  }));

export const topMarket = marketsData =>
  marketsData?.reduce((a, b) => (a.totalPoints > b.totalPoints ? a : b)).name;

export const sortedRankedPartcipants = participants =>
  participants?.sort((a, b) =>
    parseInt(a.globalRank) > parseInt(b.globalRank) ? 1 : 0
  ) || [];

export const topLeaderParticipants = participants => participants.slice(0, 3);

export const otherLeaderParticipants = participants =>
  participants.slice(3, 10);

export default Leaderboard;
