import React, { useContext } from "react";
import Leaderboard from "../../components/Leaderboard/Leaderboard";
import "./WinnersPage.scss";
import {
  getResponsiveBackgroundClass,
  getMainContainerClass,
  getPageContainerClass,
  getResponsiveClassName
} from "../../utils/responsiveClassHelpers";
import useWindowSize, {
  deviceWidthBreakpoints
} from "../../utils/useWindowSize";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import ScrollToTop from "../../components/ScrollToTop";
import Card from "../../components/Card/Card";
import LinkButton from "../../components/LinkButton/LinkButton";
import PrizesList from "../../components/PrizesList/PrizesList";
import TopLeaderCard from "../../components/TopLeaderCard/TopLeaderCard";
import { IMarket } from "../../models/participant.model";
import { ELeaderboardOption } from "../../components/Leaderboard/Leaderboard.util";
import { Event } from "../../utils/consts/event";
import { ParticipantContext } from "../../App";

import homeBannerDesktop from "../../assets/images/headers/home/homepage-hero-image.png";
import homeBannerTablet from "../../assets/images/headers/home/homepage-hero-image-tablet.png";
import homeBannerMobile from "../../assets/images/headers/home/homepage-hero-image-mobile.png";

const event = Event;

const getAboutWelcomeSectionResponsiveClass = width =>
  `welcome-section-${getResponsiveClassName(width)}`;

const getAboutContactSectionResponsiveClass = width =>
  `contact-section-container-${getResponsiveClassName(width)}`;

const url =
  "https://sparkthink.slalom.com/survey/s/f1ba0e98-34e7-4c32-a97e-34f0f7db2661";

const globalPrizesList: { title: string; text: string }[] = [
  {
    title: "1st place",
    text:
      "Global top three individual point earners will win Slalom Prism merch!"
  },
  {
    title: "2nd place",
    text:
      "Global top three individual point earners will win Slalom Prism merch!"
  },
  {
    title: "3rd place",
    text:
      "Global top three individual point earners will win Slalom Prism merch!"
  }
];

const marketPrizesList: { title: string; text: string }[] = [
  {
    title: "1st place",
    text:
      "The top market in point totals will recieve the coveted Prismatica travelling trophy!"
  },
  {
    title: "2nd place",
    text:
      "The second place market in point totals will receive a special Prismatica trophy!"
  },
  {
    title: "3rd place",
    text:
      "The third place market in point totals will receive a special Prismatica trophy!"
  }
];

const donationWinners: IMarket[] = [
  {
    name: "Toronto",
    totalDonations: "1,310.15"
  },
  {
    name: "Phoenix",
    totalDonations: "408.06"
  },
  {
    name: "Seattle",
    totalDonations: "386.25"
  }
];

const WinnersPage: React.FC = () => {
  const { width } = useWindowSize();
  const participant = useContext(ParticipantContext);
  const { MOBILE_MAX, TABLET_MAX } = deviceWidthBreakpoints;

  const bannerImg =
    width > TABLET_MAX
      ? homeBannerDesktop
      : width > MOBILE_MAX
      ? homeBannerTablet
      : homeBannerMobile;

  return (
    <ScrollToTop>
      <PageWrapper bannerImage={bannerImg}>
        <div
          className={`app-page winners-page ${getResponsiveBackgroundClass(
            width
          )}`}
        >
          <div className={getPageContainerClass(width)}>
            <div className={getMainContainerClass(width)}>
              <section
                className={`welcome-section ${getAboutContactSectionResponsiveClass(
                  width
                )} ${getAboutWelcomeSectionResponsiveClass(width)}`}
              >
                <div>
                  <div className="section-title small">
                    {event.winnersPage.noteFromPrismaticaTeamHeader}
                  </div>
                  <Card
                    containerClass={`contact-card contact-card-wide-${getResponsiveClassName(
                      width
                    )}`}
                  >
                    <div className="contact-section-title">
                      Thank you for participating!
                    </div>
                    <div className="about-page-text-body-container">
                      {event.winnersPage.noteFromPrismaticaTeam}
                    </div>
                  </Card>
                </div>
                <div>
                  <div className="section-title small section-title-last">
                    {event.winnersPage.noteFromInfoSecTeamHeader}
                  </div>
                  <Card
                    containerClass={`contact-card contact-card-wide-${getResponsiveClassName(
                      width
                    )}`}
                  >
                    <div className="about-page-text-body-container">
                      {event.winnersPage.noteFromInfoSecTeam}
                    </div>
                  </Card>
                </div>
              </section>
              <section
                className={`welcome-section contact-section ${getAboutContactSectionResponsiveClass(
                  width
                )} ${getAboutWelcomeSectionResponsiveClass(width)}`}
              >
                <Card
                  containerClass={`contact-card contact-card-${getResponsiveClassName(
                    width
                  )}`}
                >
                  <div className="contact-section-title">Got feedback?</div>
                  <div className="contact-section-text-body">
                    {event.aboutPage.aboutEventsFeedbackText}
                  </div>
                  <LinkButton
                    href="mailto: prismatica@slalom.com"
                    newTab={true}
                    text="Contact Us"
                  />
                </Card>
                <Card
                  containerClass={`contact-card contact-card-${getResponsiveClassName(
                    width
                  )}`}
                >
                  <div className="contact-section-title">
                    Join the Events Platform Build Team!
                  </div>
                  <div className="contact-section-text-body">
                    {event.aboutPage.aboutEventsJoinText}
                  </div>
                  <LinkButton href={url} newTab={true} text="Join Us" />
                </Card>
                <Card
                  containerClass={`contact-card contact-card-${getResponsiveClassName(
                    width
                  )}`}
                >
                  <div className="contact-section-title">Caught a bug?</div>
                  <div className="contact-section-text-body">
                    {event.aboutPage.aboutEventsBugText}
                  </div>
                  <LinkButton
                    href="mailto: zenith@slalom.com"
                    newTab={true}
                    text="Contact Us"
                  />
                </Card>
              </section>
              <Leaderboard
                isLocalLeaderBoard={false}
                participantData={participant}
              />
              <div className="section-container" id="gameday-winners-section">
                <div className="section-title leaderboard-title">
                  Market Donation Leaderboard
                </div>
                <Card>
                  <div id="gameday-winners">
                    {donationWinners.map((winner, idx) => (
                      <TopLeaderCard
                        data={winner}
                        key={`gameday-winner-${idx}`}
                        position={idx}
                        type={ELeaderboardOption.Market}
                        donationsLeaderboard={true}
                      />
                    ))}
                  </div>
                </Card>
              </div>
              <PrizesList prizes={marketPrizesList} type="Market" />
              <PrizesList prizes={globalPrizesList} type="Individual" />
            </div>
          </div>
        </div>
      </PageWrapper>
    </ScrollToTop>
  );
};

export default WinnersPage;
