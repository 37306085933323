import React, { useState } from "react";
import { getResponsiveClassName } from "../../utils/responsiveClassHelpers";
import useWindowSize from "../../utils/useWindowSize";
import closeIcon from "../../assets/images/close-icon.png";
import "./Announcements.scss";

type AnnouncementsPropTypes = {
  announcementData: any[];
};
const Announcements: React.FC<AnnouncementsPropTypes> = ({
  announcementData
}): React.ReactElement => {
  const { width } = useWindowSize();
  const [announcements, setAnnouncements] = useState(announcementData);

  const handleCloseAnnouncement = (announcementId: string, e?: any) => {
    e?.preventDefault();
    setAnnouncements(announcements.filter(({ id }) => id !== announcementId));
    sessionStorage.setItem(`seenAnnouncement${announcementId}`, "true");
  };

  return (
    <div className={`announcement-container-${getResponsiveClassName(width)}`}>
      {announcements.map(({ id, text }) => {
        return (
          <>
            {!sessionStorage.getItem(`seenAnnouncement${id}`) && (
              <div
                key={id}
                className={`announcement-${getResponsiveClassName(width)}`}
              >
                {text}
                <img
                  className="announcement-close"
                  src={closeIcon}
                  alt="x to close"
                  onClick={() => handleCloseAnnouncement(id)}
                />
              </div>
            )}
          </>
        );
      })}
    </div>
  );
};

export default Announcements;
