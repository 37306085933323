import React from "react";
import "./SponsorTile.scss";
import { getResponsiveClassName } from "../../utils/responsiveClassHelpers";
import useWindowSize from "../../utils/useWindowSize";

type SponsorType = {
  name: string;
  link: string;
  image: any;
  blurb: string;
};

interface SponsorTileProps {
  sponsor: SponsorType;
}

const handleClick = link => {
  window.open(link, "_blank");
};

const SponsorTile = ({ sponsor }: SponsorTileProps) => {
  // const renderSponsorTile = ({ name, blurb }: SponsorType): JSX.Element => {
  //   return (
  //     <div className="sponsor-tile-info-container">
  //       <div className="sponsor-name-container">{`${name}`}</div>
  //       <div className="sponsor-blurb-container">{`${blurb}`}</div>
  //     </div>
  //   );
  // };

  const { image, link } = sponsor;
  const { width } = useWindowSize();
  return (
    <div
      className="sponsor-tile-container sponsor-tile-margin"
      data-testid="sponsor-tile-container"
    >
      <div>
        <div
          className={`sponsor-tile-image-container sponsor-tile-image-${getResponsiveClassName(
            width
          )}`}
          style={{
            backgroundImage: `url(${image})`
          }}
          onClick={() => handleClick(link)}
        />
        {/* {renderSponsorTile(sponsor)} */}
      </div>
    </div>
  );
};

export default SponsorTile;
