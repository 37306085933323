import React, { FC, useEffect, useState } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Amplify from "aws-amplify";
import { amplifyConfig } from "./config";
import AboutPage from "./pages/AboutPage";
import TopNavigationBar from "./components/TopNavigationBar";
import ErrorPage from "./pages/ErrorPage";
import SplashPage from "./pages/SplashPage";
import { getParticipant, refreshToken } from "./ApiHelper";
import "./App.scss";
import { handleUserAuth } from "./AuthHelper";
import { LOCAL_USER_DATA } from "./utils/consts/amplifyKeys";
import {
  isBeforeEventStart,
  showEndAnnouncements,
  showFullSite,
  showWinners
} from "./utils/eventUtil";
import EventsPage from "./pages/EventsPage";
import FAQsPage from "./pages/FAQsPage";
import HomePage from "./pages/HomePage";
// import PrelaunchPage from "./pages/PrelaunchPage";
import WinnersPage from "./pages/WinnersPage/WinnersPage";
import WrapPage from "./pages/WrapPage";
// import PrelaunchPage from "./pages/PrelaunchPage";
import { QueryConfig, useQuery } from "react-query";
import { IParticipant } from "./models/participant.model";

Amplify.configure(amplifyConfig);

const url = window.location.href;
const isProd = url.includes("pride.events.slalom.com");
const isDev = url.includes("dev.pride.hacktober.ninja");
const isTest = url.includes("test.pride.hacktober.ninja");

const isLocal = url.includes("http://localhost");

type SiteViewProps = {
  showNavBars: boolean;
  setShowNavBars: any;
};

export const ParticipantContext: React.Context<IParticipant> = React.createContext(
  null
);

// const Prelaunch: React.FC<SiteViewProps> = ({
//   setShowNavBars
// }): React.ReactElement => (
//   <Switch>
//     <Route exact path="/" render={() => <PrelaunchPage />} />
//     <Route
//       path="/events"
//       render={() => <EventsPage showClaimPoints={false} showPrelaunch={true} />}
//     />
//     <Route path="/faqs" render={() => <FAQsPage />} />
//     <Route render={() => <ErrorPage setShowNavBars={setShowNavBars} />} />
//   </Switch>
// );

const FullSite: React.FC<SiteViewProps> = ({
  showNavBars,
  setShowNavBars
}): React.ReactElement => (
  <>
    {showNavBars && <TopNavigationBar />}
    <Switch>
      <Route exact path="/" render={() => <HomePage />} />
      <Route
        path="/events"
        render={() => (
          <EventsPage showClaimPoints={true} showPrelaunch={false} />
        )}
      />
      <Route path="/about" render={() => <AboutPage />} />
      <Route path="/faqs" render={() => <FAQsPage />} />
      <Route render={() => <ErrorPage setShowNavBars={setShowNavBars} />} />
    </Switch>
    {showNavBars}
  </>
);

const EndAnnouncements: React.FC<SiteViewProps> = ({
  setShowNavBars,
  showNavBars
}): React.ReactElement => (
  <>
    <Switch>
      <Route exact path="/" render={() => <WrapPage />} />
      <Route render={() => <ErrorPage setShowNavBars={setShowNavBars} />} />
    </Switch>
    {/* {showNavBars} */}
  </>
);

const Winners: React.FC<SiteViewProps> = ({
  setShowNavBars,
  showNavBars
}): React.ReactElement => (
  <>
    {showNavBars && <TopNavigationBar />}
    <Switch>
      <Route exact path="/">
        <Redirect to="/winners" />
      </Route>
      <Route exact path="/winners" render={() => <WinnersPage />} />
      {/* <Route path="/about" render={() => <AboutPage />} /> */}
      <Route render={() => <ErrorPage setShowNavBars={setShowNavBars} />} />
    </Switch>
    {showNavBars}
  </>
);

const SiteView: React.FC<SiteViewProps> = ({ showNavBars, setShowNavBars }) => {
  const { data: participantData } = useQuery<IParticipant>({
    queryKey: "participant",
    queryFn: getParticipant
  } as QueryConfig<IParticipant, any>);

  return (
    <ParticipantContext.Provider value={participantData}>
      <BrowserRouter>
        {/* uncomment to change screens for development */}
        {/* {isLocal && (
          <FullSite showNavBars={showNavBars} setShowNavBars={setShowNavBars} />
        )} */}
        {/* {isLocal && <Prelaunch />} */}
        {/* {isLocal && (
          <EndAnnouncements
            showNavBars={showNavBars}
            setShowNavBars={setShowNavBars}
          />
        )} */}
        {(isLocal || isDev) && (
          <Winners showNavBars={showNavBars} setShowNavBars={setShowNavBars} />
        )}

        {/* uncomment to change screens for testing in dev */}
        {/* {isDev && (
          <FullSite showNavBars={showNavBars} setShowNavBars={setShowNavBars} />
        )} */}

        {/*
      Pre-launch:
      This usually has an introductory page and links to view the upcoming event calendar and FAQs.
      There is no home page because the Question of the Day / Leaderboard should only show
      during the event dates.
    */}
        {(isProd || isTest) && isBeforeEventStart && <SplashPage />}
        {/*
      Main app:
      These are the main routes to render during the event.
    */}
        {(isProd || isTest) && showFullSite && (
          <FullSite showNavBars={showNavBars} setShowNavBars={setShowNavBars} />
        )}
        {/*
      Wrap:
      Similar to the pre-launch, except this is rendered after the event ends,
      and has a screen that summarizes the event, gives shout-outs to particular teams, etc.
    */}
        {(isProd || isTest) && showEndAnnouncements && (
          <EndAnnouncements
            showNavBars={showNavBars}
            setShowNavBars={setShowNavBars}
          />
        )}
        {/*
      Winners:
      Similar to home-page, except this is rendered after the event ends and winners are announced.
      This will show all of the event winners and prizes
    */}
        {(isProd || isTest) && showWinners && (
          <Winners showNavBars={showNavBars} setShowNavBars={setShowNavBars} />
        )}
      </BrowserRouter>
    </ParticipantContext.Provider>
  );
};

const App: FC = () => {
  const [showNavBars, setShowNavBars] = useState(true);
  const [userData, setUserData] = useState(
    JSON.parse(String(localStorage.getItem(LOCAL_USER_DATA)))
  );

  useEffect(() => {
    refreshToken().then(() => {
      handleUserAuth(userData, setUserData);
    });
  }, [userData]);

  return (
    <div className="App">
      {userData ? (
        <SiteView setShowNavBars={setShowNavBars} showNavBars={showNavBars} />
      ) : (
        <SplashPage />
      )}
    </div>
  );
};

export default App;
