import React from "react";
import "./FAQsPage.scss";
import useWindowSize, {
  deviceWidthBreakpoints
} from "../../utils/useWindowSize";
import FAQList from "../../components/FAQList/FAQList";
import {
  getResponsiveBackgroundClass,
  getMainContainerClass,
  getPageContainerClass
} from "../../utils/responsiveClassHelpers";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import ScrollToTop from "../../components/ScrollToTop";
import { getResponsiveClassName } from "../../utils/responsiveClassHelpers";
import { Event } from "../../utils/consts/event";
import faqBannerScreen from "../../assets/images/headers/faq/faq-hero-image.png";
import faqBannerTablet from "../../assets/images/headers/faq/faq-hero-image-tablet.png";
import faqBannerMobile from "../../assets/images/headers/faq/faq-hero-image-mobile.jpg";

const { MOBILE_MAX, TABLET_MAX } = deviceWidthBreakpoints;

const FAQsPage: React.FC = (): React.ReactElement => {
  const { width } = useWindowSize();

  const getFaqHeaderResponsiveClass = width =>
    `faq-header-${getResponsiveClassName(width)}`;

  const event = Event;
  const faqBanner =
    width > TABLET_MAX
      ? faqBannerScreen
      : width > MOBILE_MAX
      ? faqBannerTablet
      : faqBannerMobile;

  console.log(width, faqBanner);

  return (
    <div className="faq-page">
      <ScrollToTop>
        <PageWrapper bannerImage={faqBanner}>
          <div className={`app-page ${getResponsiveBackgroundClass(width)}`}>
            <div className={getPageContainerClass(width)}>
              <div className={getMainContainerClass(width)}>
                <div className="section-container">
                  <div></div>
                  <div className="section-title">
                    Frequently Asked Questions
                  </div>
                  {event.faqSections.map((val, index) => (
                    <div key={index}>
                      <div
                        className={`faq-header ${getFaqHeaderResponsiveClass(
                          width
                        )}`}
                      >
                        <p>{val.name}</p>
                      </div>
                      <FAQList faqs={val.sectionData} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </PageWrapper>
      </ScrollToTop>
    </div>
  );
};

export default FAQsPage;
