import React from "react";
import "./Footer.scss";
import useWindowSize, {
  deviceWidthBreakpoints
} from "../../utils/useWindowSize";
import { eventHasEnded } from "../../utils/eventUtil";

const Footer = () => {
  const { width } = useWindowSize();
  const { MOBILE_MAX, TABLET_MAX } = deviceWidthBreakpoints;
  const bugReportingEmail = "zenith@slalom.com";
  const feedbackEmail = "prismatica@slalom.com";

  return (
    <div className="footer-container">
      <footer
        className={`${width < TABLET_MAX && "footer-tablet"} ${width <
          MOBILE_MAX && "footer-mobile"}`}
      >
        {!eventHasEnded && (
          <div className="footer-text-container">
            <p>Have questions? Visit the &apos;FAQ&apos; page to learn more.</p>
          </div>
        )}
        <div className="footer-text-container">
          <p>
            Have feedback? Please share with{" "}
            <a
              href={`mailto: ${feedbackEmail}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {feedbackEmail}
            </a>
          </p>
        </div>
        <div className="footer-text-container">
          <p>
            Caught a bug? Report it to{" "}
            <a
              href={`mailto: ${bugReportingEmail}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {bugReportingEmail}
            </a>
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
