import React, { FC, useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-query";
import { getEvents } from "../../ApiHelper";
import DropdownFilter from "../../components/Dropdown";
import eventsBannerDesktop from "../../assets/images/headers/events/events-hero-image.png";
import eventsBannerTablet from "../../assets/images/headers/events/events-hero-image-tablet.png";
import eventsBannerMobile from "../../assets/images/headers/events/events-hero-image-mobile.png";
import "./EventsPage.scss";
import { dayFilterOptions, timeFilterOptions } from ".";
import {
  getResponsiveBackgroundClass,
  getPageContainerClass
} from "../../utils/responsiveClassHelpers";
import Tooltip from "../../components/Tooltip/Tooltip";
import { getResponsiveClassName } from "../../utils/responsiveClassHelpers";
import useWindowSize, {
  deviceWidthBreakpoints
} from "../../utils/useWindowSize";
import EventCardGrid from "./EventCardGrid";
import ClaimPoints from "../../components/ClaimPoints";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import Card from "../../components/Card/Card";
import { EEventsOption, getMarketFilterOptions } from "./EventsPage.util";
import PastEventCardGrid from "./PastEventCardGrid";
import { FilterItem } from "../../models/filter.model";
import { Event } from "../../utils/consts/event";

export const VIEW_RECORDINGS_LINK = Event.wrapPage.viewRecordingLink;
const { MOBILE_MAX, TABLET_MAX } = deviceWidthBreakpoints;
const showWarning = false;

type EventsPageProps = {
  showClaimPoints?: boolean;
  showPrelaunch?: boolean;
};

export const getEventsPageState = prelaunch =>
  prelaunch ? "prelaunch" : "full-site";

type EventOptionsProps = {
  selectedOption: string;
  setSelectedOption: (selectedOption: EEventsOption) => void;
};

const EventOptions: FC<EventOptionsProps> = ({
  selectedOption,
  setSelectedOption
}: EventOptionsProps): JSX.Element => {
  const isSelectedStyleApplied = (option: string) =>
    option === selectedOption
      ? "selected-event-option"
      : "unselected-event-option";
  const eventsOptions = [
    { title: "Upcoming", type: EEventsOption.Upcoming },
    { title: "Past", type: EEventsOption.Past }
  ];
  return (
    <div className="events-options">
      {eventsOptions.map(eventsOption => {
        const { title, type } = eventsOption;
        return (
          <div
            key={title}
            className={`events-option ${isSelectedStyleApplied(title)}`}
            onClick={() => setSelectedOption(type)}
          >
            {title}
          </div>
        );
      })}
    </div>
  );
};

const EventsPage: React.FC<EventsPageProps> = ({
  showClaimPoints,
  showPrelaunch
}): React.ReactElement => {
  const { width } = useWindowSize();
  const [selectedOption, setSelectedOption] = useState<EEventsOption>(
    EEventsOption.Upcoming
  );
  const [selectedMarkets, setSelectedMarkets] = useState<string[]>([]);
  const [selectedDays, setSelectedDays] = useState<string[]>(["0"]);
  const [selectedTimes, setSelectedTimes] = useState<string[]>(["all"]);
  const [speakerProfiles, setSpeakerProfiles] = useState<string[]>([]);

  const eventsBanner =
    width > TABLET_MAX
      ? eventsBannerDesktop
      : width > MOBILE_MAX
      ? eventsBannerTablet
      : eventsBannerMobile;

  const {
    data: eventsData,
    error: eventsError,
    isLoading: eventsAreLoading
  } = useQuery<any[]>(["events"], getEvents);
  const filterContainerClass = `filter-container-${getResponsiveClassName(
    width
  )}`;

  const marketFilterOptions: FilterItem[] = getMarketFilterOptions(eventsData);

  return (
    <div className={`events-page-${getEventsPageState(showPrelaunch)}`}>
      <PageWrapper bannerImage={eventsBanner}>
        <div
          className={`app-page events-page__pink ${getResponsiveBackgroundClass(
            width
          )}`}
        >
          <div className={getPageContainerClass(width)}>
            {showWarning && (
              <section className="temp-card-container">
                <Card>
                  <div className="prelaunch-card-title">
                    <span role="img" aria-label="horn">
                      📣
                    </span>{" "}
                    Hang Tight!!!
                  </div>
                  <div className="temp-card-body">
                    <span className="bold">
                      Add to calendar functionality will be up shortly.
                    </span>{" "}
                    We apologize for the inconvenience. Check back soon!
                  </div>
                </Card>
              </section>
            )}
            <div className="events-container">
              <ClaimPoints />

              <div className="events-tooltip">
                <div className="section-title">Events</div>
                <Tooltip>
                  <p>
                    To attend an event, click the button and an email pop-up
                    will appear.
                  </p>
                  <p>
                    Please send an email to inform the event organizer that you
                    would like to attend the event.
                  </p>
                </Tooltip>
              </div>
              <div
                className={
                  "app-card-display filter-container " + filterContainerClass
                }
              >
                <EventOptions
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                />
                <div className={`filter-row-${getResponsiveClassName(width)}`}>
                  <div>
                    <p className="dropdown-label">Filter by market</p>
                    <DropdownFilter
                      placeholder="Select market"
                      onChange={(event, data) =>
                        setSelectedMarkets(data.value as string[])
                      }
                      options={marketFilterOptions}
                      multiple
                      selection
                      clearable
                      className="events-filter"
                      label={""}
                    />
                  </div>

                  <div>
                    <p className="dropdown-label">Filter by day</p>
                    <DropdownFilter
                      placeholder="Select day"
                      onChange={(event, data) =>
                        setSelectedDays(data.value as string[])
                      }
                      color="#deff4d"
                      options={dayFilterOptions}
                      multiple
                      selection
                      clearable
                      className="events-filter"
                      label={""}
                    />
                  </div>
                  {selectedOption === EEventsOption.Upcoming && (
                    <div>
                      <p className="dropdown-label">Filter by time</p>
                      <DropdownFilter
                        placeholder="Select time"
                        onChange={(event, data) => {
                          setSelectedTimes(data.value as string[]);
                        }}
                        options={timeFilterOptions}
                        multiple
                        selection
                        clearable
                        className="events-filter"
                        label={""}
                      />
                    </div>
                  )}
                </div>
              </div>
              {selectedOption === EEventsOption.Upcoming ? (
                <div className="events-container">
                  <EventCardGrid
                    eventsAreLoading={eventsAreLoading}
                    eventsError={eventsError as string}
                    eventsData={eventsData}
                    selectedMarkets={selectedMarkets}
                    selectedDays={selectedDays}
                    selectedTimes={selectedTimes}
                    speakerProfiles={speakerProfiles}
                    setSpeakerProfiles={setSpeakerProfiles}
                  />
                </div>
              ) : (
                <div className="events-container">
                  <PastEventCardGrid
                    eventsAreLoading={eventsAreLoading}
                    eventsError={eventsError as string}
                    eventsData={eventsData}
                    selectedDays={selectedDays}
                    selectedMarkets={selectedMarkets}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </PageWrapper>
    </div>
  );
};

EventsPage.propTypes = {
  showClaimPoints: PropTypes.bool,
  showPrelaunch: PropTypes.bool
};

export default EventsPage;
