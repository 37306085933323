import React from "react";
import FAQTile from "../FAQTile/FAQTile";

interface FAQListProps {
  faqs: any;
}

const renderFAQList = (faqs: any): JSX.Element => {
  return faqs.map(faq => <FAQTile key={faq.title} faq={faq} />);
};
const FAQList = ({ faqs }: FAQListProps) => {
  return <div>{renderFAQList(faqs)}</div>;
};

export default FAQList;
