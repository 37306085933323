import React, { FC, useEffect, useRef } from "react";
import "./WelcomeModal.scss";
import Card from "../Card/Card";
import Button from "../Button/Button";
import { getResponsiveClassName } from "../../utils/responsiveClassHelpers";
import useWindowSize, {
  deviceWidthBreakpoints
} from "../../utils/useWindowSize";
import answerIcon from "../../assets/images/answer-icon.png";
import awardsIcon from "../../assets/images/awards-icon.png";
import eventsIcon from "../../assets/images/events-icon.png";
import { confetti } from "tsparticles-confetti";
import useDetectOutsideClick from "../../hooks/useDetectOutsideClick";

const { MOBILE_MAX } = deviceWidthBreakpoints;

type WelcomeModalProps = {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const throwConfetti = () => {
  const end = Date.now() + 1 * 1000;
  const frame = () => {
    confetti({
      particleCount: 4,
      angle: 50,
      spread: 80,
      origin: { x: 0 },
      shapes: ["image"],
      scalar: 3,
      zIndex: 1000,
      shapeOptions: {
        image: [
          {
            src: require("../../assets/images/confetti/confetti-1.svg"),
            width: 69,
            height: 90
          },
          {
            src: require("../../assets/images/confetti/confetti-2.svg"),
            width: 58,
            height: 83
          },
          {
            src: require("../../assets/images/confetti/confetti-3.svg"),
            width: 106,
            height: 148
          }
        ]
      }
    });

    confetti({
      particleCount: 4,
      angle: 130,
      spread: 80,
      origin: { x: 1 },
      shapes: ["image"],
      scalar: 3,
      zIndex: 1000,
      shapeOptions: {
        image: [
          {
            src: require("../../assets/images/confetti/confetti-1.svg"),
            width: 69,
            height: 90
          },
          {
            src: require("../../assets/images/confetti/confetti-2.svg"),
            width: 58,
            height: 83
          },
          {
            src: require("../../assets/images/confetti/confetti-3.svg"),
            width: 106,
            height: 148
          }
        ]
      }
    });

    if (Date.now() < end) {
      requestAnimationFrame(frame);
    }
  };
  frame();
};

const WelcomeModal: FC<WelcomeModalProps> = ({
  show,
  setShow
}): React.ReactElement => {
  const modalRef = useRef();
  const { width } = useWindowSize();

  useDetectOutsideClick(modalRef, () => setShow(false));

  useEffect(() => {
    if (show) throwConfetti();
  }, [show]);

  const handleClick = (e?: any) => {
    e.preventDefault();
    setShow(false);
  };

  return (
    <div className={`welcome-modal ${show ? "active" : ""}`}>
      <div
        ref={modalRef}
        className={`welcome-modal-container-${getResponsiveClassName(width)}`}
      >
        <div className={`welcome-title-${getResponsiveClassName(width)}`}>
          how to earn points
        </div>
        <Card
          containerClass="card-container"
          contentStyle={{
            border: "0px",
            background: "none",
            backdropFilter: "none"
          }}
        >
          <div className="welcome-modal-body">
            <div className="welcome-modal-section">
              {width > MOBILE_MAX && (
                <img
                  src={answerIcon}
                  className="welcome-modal-section__image"
                  alt="thumbs up"
                ></img>
              )}
              <div className="welcome-modal-section__content">
                <div className="welcome-modal-section__content__title">
                  Answer the question of the day
                </div>
                <div className="welcome-modal-section__content__body">
                  Answer the question of the day correctly to earn points!
                  Questions refresh every 12 hours, and points are automatically
                  added.
                </div>
              </div>
            </div>
            <div className="welcome-modal-section">
              {width > MOBILE_MAX && (
                <img
                  src={eventsIcon}
                  className="welcome-modal-section__image"
                  alt="thumbs up"
                ></img>
              )}
              <div className="welcome-modal-section__content">
                <div className="welcome-modal-section__content__title">
                  Attend events to get a code
                </div>
                <div className="welcome-modal-section__content__body">
                  Attend events throughout the week! Each event will provide you
                  with a special code. Enter that code in the “Events” section
                  of our site to earn points!
                </div>
              </div>
            </div>
            <div className="welcome-modal-section">
              {width > MOBILE_MAX && (
                <img
                  src={awardsIcon}
                  className="welcome-modal-section__image"
                  alt="thumbs up"
                ></img>
              )}
              <div className="welcome-modal-section__content">
                <div className="welcome-modal-section__content__title">
                  Other ways to earn points
                </div>
                <div className="welcome-modal-section__content__body">
                  Check out the “Other Ways to Earn Points” section at the
                  bottom of the home page for more ways to earn some extra
                  points. Some of these point-earning activities may not have
                  points added automatically; they will be added by the end of
                  the event.
                </div>
              </div>
            </div>
          </div>
        </Card>
        <Button
          type="button"
          style={`welcome`}
          onClick={handleClick}
          value="Okay, let's go!"
        />
      </div>
    </div>
  );
};

export default WelcomeModal;
