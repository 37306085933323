import React from "react";
import LinkButton from "../../components/LinkButton/LinkButton";
import heartHandshake from "../../assets/images/heart-handshake.png";
import useWindowSize, {
  deviceWidthBreakpoints
} from "../../utils/useWindowSize";
import "./Donate.scss";
import Card from "../Card/Card";
import { EButtonStyle } from "../../utils/buttonUtils";

const { MOBILE_MAX, TABLET_MAX } = deviceWidthBreakpoints;

const Donate: any = () => {
  const { width } = useWindowSize();
  const viewport =
    width <= MOBILE_MAX ? "mobile" : width <= TABLET_MAX ? "tablet" : "desktop";

  return (
    <div className={`section-container donate-section-container-${viewport}`}>
      <div className="section-donate">
        <div className="section-title small">
          Donate to your local non-profits in honor of Pride Month
        </div>
        <div className="donate">
          <Card containerClass="donate-description">
            <div className="donate-container">
              <img
                src={heartHandshake}
                alt="hearthandshake-icon"
                className="hearthandshake-icon"
              />
              <p>Thank you!</p>
            </div>
            <div className="donate-text-container">
              <p>
                Please donate to one of our Pride Month fundraisers{" "}
                <b>to earn points</b>. We are supporting an LGBTQ+ organization
                in each country we have an office in.
              </p>
            </div>
            <LinkButton
              text="Donate"
              style={EButtonStyle.PRIMARY}
              href="https://twodegrees1.sharepoint.com/teams/IandD/SitePages/Pride-Month-Prism.aspx?xsdata=MDV8MDF8fDMzMzYwNjZjNDU0YTQ5NDBkZTA0MDhkYjU2ZDY0Y2QwfDljYTc1MTI4YTI0NDQ1OTY4NzdiZjI0ODI4ZTQ3NmUyfDB8MHw2MzgxOTkyNTA2MTQ4MDM5NzJ8VW5rbm93bnxWR1ZoYlhOVFpXTjFjbWwwZVZObGNuWnBZMlY4ZXlKV0lqb2lNQzR3TGpBd01EQWlMQ0pRSWpvaVYybHVNeklpTENKQlRpSTZJazkwYUdWeUlpd2lWMVFpT2pFeGZRPT18MXxMMk5vWVhSekx6RTVPalV6TmpNNFlXRXdNbVJpT0RSbVltRTVNamszWkRneE1EWTBZVEE1TnpoaVFIUm9jbVZoWkM1Mk1pOXRaWE56WVdkbGN5OHhOamcwTXpJNE1qWXdNekl5fGNiMTRkNjVjNmViZDRjYzBkZTA0MDhkYjU2ZDY0Y2QwfGMwZTE4Nzg3MmRiMzQ0YmQ5ODg0MTU1Nzk4MDYyZTkz&sdata=VGJtRWlyZzc5RCt5Y0tNaS9LcEd3NzE1UWVyeE5RNWZwVmhOLzF1SlBCYz0%3D&ovuser=9ca75128-a244-4596-877b-f24828e476e2%2Cavery.green%40slalom.com&OR=Teams-HL&CT=1684348605820&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiIyOC8yMzA0MDIwMjcwNSIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D&SafelinksUrl=https%3A%2F%2Ftwodegrees1.sharepoint.com%2Fteams%2FIandD%2FSitePages%2FPride-Month-Prism.aspx"
              newTab={true}
            />
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Donate;
