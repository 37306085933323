import React, { useState, FC } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import DropdownFilter from "../Dropdown";
import { getMarkets, getParticipants } from "../../ApiHelper";
import { sortedRankedPartcipants, topLeaderParticipants } from ".";
import useWindowSize, {
  deviceWidthBreakpoints
} from "../../utils/useWindowSize";
import "./Leaderboard.scss";
import Tooltip from "../Tooltip/Tooltip";
import { ELeaderboardOption } from "./Leaderboard.util";
import { getResponsiveClassName } from "../../utils/responsiveClassHelpers";
import Card from "../Card/Card";
import Securyeti from "../Securyeti/Securyeti";
import { IMarket, IParticipant } from "../../models/participant.model";
import LeaderboardOptions from "./LeaderboardOptions/LeaderboardOptions";
import LeaderboardOrFallback from "./LeaderboardOrFallback/LeaderboardOrFallback";

const { MOBILE_MAX } = deviceWidthBreakpoints;

const leaderBoardContainerResponsiveClassName = width =>
  `leaderboard-container leaderboard-container-${getResponsiveClassName(
    width
  )}`;

const sectionTitleString = isLocalLeaderBoard =>
  isLocalLeaderBoard ? "Local Winners" : "Participant Leaderboard";

const renderMobileLeaderBoardOptions = (
  width: number,
  setSelectedOption: (selectedOption: ELeaderboardOption) => void
) => {
  const leaderboardOptions = [
    { text: "Global", value: ELeaderboardOption.Global },
    { text: "Market", value: ELeaderboardOption.Market },
    { text: "Local", value: ELeaderboardOption.Local }
  ];
  return width <= MOBILE_MAX ? (
    <div className="leaderboard-options-mobile">
      <DropdownFilter
        placeholder="Global"
        defaultValue="Global"
        onChange={(event, data) =>
          setSelectedOption(data.value as ELeaderboardOption)
        }
        options={leaderboardOptions}
        selection
        label=""
      />
    </div>
  ) : null;
};

type LeaderboardProps = {
  isLocalLeaderBoard: Boolean;
  participantData: IParticipant;
};

const globalWinners: IParticipant[] = [
  {
    firstName: "Sabrina",
    lastName: "Duflo",
    market: "Phoenix",
    totalPoints: "7,000"
  },
  {
    firstName: "Dave",
    lastName: "Ghosh",
    market: "Seattle",
    totalPoints: "6,800"
  },
  {
    firstName: "Eduardo",
    lastName: "Paredes",
    market: "Phoenix",
    totalPoints: "6,700"
  }
];

const marketWinners: IMarket[] = [
  {
    name: "Seattle",
    totalPoints: 82600
  },
  {
    name: "Phoenix",
    totalPoints: 65400
  },
  {
    name: "Chicago",
    totalPoints: 40400
  }
];

const Leaderboard: FC<LeaderboardProps> = ({
  isLocalLeaderBoard = false,
  participantData
}: LeaderboardProps) => {
  const { width } = useWindowSize();
  const [selectedOption, setSelectedOption] = useState<ELeaderboardOption>(
    ELeaderboardOption.Global
  );
  const {
    // data: globalData,
    error: globalError,
    isLoading: globalIsLoading
  } = useQuery(["global", "Global"], getParticipants);
  const {
    data: localData,
    error: localError,
    isLoading: localIsLoading
  } = useQuery(["local", participantData?.market], getParticipants);

  const {
    // data: marketsData,
    error: marketsError,
    isLoading: marketsIsLoading
  } = useQuery(["markets"], getMarkets);

  let participants = null;
  let error = null;
  let isLoading = null;
  if (selectedOption === ELeaderboardOption.Global) {
    participants = globalWinners;
    error = globalError;
    isLoading = globalIsLoading;
  } else if (selectedOption === ELeaderboardOption.Market) {
    participants = marketWinners;
    error = marketsError;
    isLoading = marketsIsLoading;
  } else if (selectedOption === ELeaderboardOption.Local) {
    participants = localData;
    error = localError;
    isLoading = localIsLoading;
  }

  const sortedParticipants =
    participants && sortedRankedPartcipants(participants);

  const winningParticipants =
    sortedParticipants && topLeaderParticipants(sortedParticipants);

  return (
    <div className="leaderboard-section section-container">
      <div className="section-header">
        <div className="section-title leaderboard-title">
          {sectionTitleString(isLocalLeaderBoard)}
        </div>

        <Tooltip>
          <p className="leaderboard-tooltip">
            This leaderboard updates in real time. To learn more, check out the{" "}
            <Link to="/faqs">FAQ page</Link>.
          </p>
        </Tooltip>
        <Securyeti />
      </div>
      {width > MOBILE_MAX && (
        <LeaderboardOptions
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
      )}
      <Card
        contentStyle={{
          background:
            sortedParticipants?.length === 0
              ? "linear-gradient(.25turn, rgba(255, 255, 255, 0.42), rgba(255, 255, 255, 0.06))"
              : undefined,
          border: "none"
        }}
      >
        <div
          className={`${leaderBoardContainerResponsiveClassName(
            width
          )} ${selectedOption}-container`}
        >
          {width <= MOBILE_MAX &&
            renderMobileLeaderBoardOptions(width, setSelectedOption)}
          <LeaderboardOrFallback
            selectedOption={selectedOption}
            isLocalLeaderBoard={isLocalLeaderBoard}
            sortedParticipants={winningParticipants}
            isLoading={isLoading}
            error={error}
          />
        </div>
      </Card>
    </div>
  );
};

export default Leaderboard;
